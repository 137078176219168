function addScript(path) {
  var script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = path
  document.getElementsByTagName('head')[0].appendChild(script)
}
var userAgent = window.navigator.userAgent.toLowerCase()
if (userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1) {
  addScript(
    'https://cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv.min.js'
  )
  addScript(
    'https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js'
  )
  addScript(
    'https://cdnjs.cloudflare.com/ajax/libs/object-fit-images⁄3.2.4/ofi.min.js'
  )
  addScript(
    'https://cdnjs.cloudflare.com/ajax/libs/flexibility/2.0.1/flexibility.js'
  )
  addScript(
    'https://cdnjs.cloudflare.com/ajax/libs/stickyfill/2.1.0/stickyfill.min.js'
  )

  if (document.documentMode && navigator.msSaveOrOpenBlob) {
    window.addEventListener('click', function (eve) {
      var a = eve.target
      if (!a.hasAttribute('download')) return
      eve.preventDefault()
      var filename = a.getAttribute('download')
      var xhr = new XMLHttpRequest()
      xhr.open('GET', a.href)
      xhr.responseType = 'blob'
      xhr.send()
      xhr.onload = function () {
        navigator.msSaveOrOpenBlob(xhr.response, filename)
      }
    })
  }
}
